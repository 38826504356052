import { Auth } from '@aws-amplify/auth';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Actions as LOGINACTIONS } from '../../store/actions/authenticationActions';
import { Actions as HELPACTIONS } from '../../store/actions/helpActions';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as SIDEBARACTIONS } from '../../store/actions/sidebarActions';

import { BrandLogo, SidebarInfoToogleBox, SidebarToogleBox } from '../../styledComponents/GlobalStyle';

const Navbar = ({
  organisations,
  organisationOptions,
  selectedOrganisation,
  updateOrganisation,
  toggleSidebar,
  toggleHelp,
  fullName,
  showHelpSidebar,
  logout,
  branding,
  currentOrg,
  drgWhiteLabel,
}) => {
  const { body } = branding.fonts?.[0].font || {};
  const { logo, appTopLeftLogo, name } = branding || {};
  const { navbarUserDropdownBg } = branding?.colors || {};

  const [showUserMenu, toggleUserMenu] = useState(false);

  let history = useHistory();

  const signOut = async () => {
    try {
      localStorage.removeItem('Organisation');
      localStorage.removeItem('orgDetails');
      await Auth.signOut();
      history.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 992) toggleSidebar();
  }, [toggleSidebar]);

  const updateOrg = (organisationOption) => {
    updateOrganisation(organisations.find((item) => item.id === organisationOption.value));
    if (window.location.pathname === `/organisations/${currentOrg}`) {
      history.push(`${organisationOption.value}`);
    }
    localStorage.setItem('Organisation', organisationOption.value);
    const orgDetails = _.find(organisations, { id: organisationOption.value });
    if (orgDetails) localStorage.setItem('orgDetails', JSON.stringify(orgDetails));
  };

  return (
    <header className={`header fixed-top clearfix ${showHelpSidebar ? `merge-header` : ``}`}>
      <BrandLogo className='brand'>
        <div
          className='logo logo-box'
          style={{
            cursor: 'pointer',
            fontSize: '21px',
            color: '#2e2e2e',
            float: 'left',
            margin: '14px 0 6px 25px',
            textTransform: 'uppercase',
          }}
          onClick={() => {
            if (drgWhiteLabel) window.location.href = 'https://digitalrg.com/dashboard';
            else history.push('/');
          }}>
          <img src={appTopLeftLogo || logo} alt={name} style={{ maxHeight: 56, maxWidth: 180 }} />
        </div>
        <SidebarToogleBox className='sidebar-toggle-box'>
          <div onClick={toggleSidebar} className='fa fa-bars'></div>
        </SidebarToogleBox>
      </BrandLogo>

      <div className='top-nav clearfix'>
        <ul className='nav pull-right top-menu'>
          {/* <PaymentNeeded /> */}
          <li className='dropdown'>
            <button
              data-toggle='dropdown'
              className='dropdown-toggle icon-user navbar-user-dropdown'
              href='#'
              style={{ '--navbar-userdropdown-bg': navbarUserDropdownBg, border: 0, borderRadius: '100px' }}
              onClick={() => toggleUserMenu(!showUserMenu)}>
              <i className='fa fa-user'></i>
              <span className='username' style={{ fontFamily: body }}>
                {' '}
                &#160; {fullName} &#160;{' '}
              </span>
              <b className='caret'></b>
            </button>
            <ul
              className='dropdown-menu extended logout'
              style={showUserMenu ? { display: 'block', cursor: 'pointer' } : {}}>
              <li>
                <a
                  href='/profile'
                  onClick={() => {
                    history.push('/profile');
                    toggleUserMenu(!showUserMenu);
                  }}>
                  <i className=' fa fa-suitcase'></i>Profile
                </a>
              </li>
              <li>
                <a
                  href='/'
                  onClick={(e) => {
                    e.preventDefault();
                    signOut();
                    logout(null);
                  }}>
                  <i className='fa fa-key'></i> Log Out
                </a>
              </li>
            </ul>
          </li>

          <li>
            {
              <div style={{ width: '200px', float: 'left', marginBottom: '0px', color: 'black', fontFamily: body }}>
                <div>
                  <Select
                    className='org-select-dropdown'
                    value={selectedOrganisation}
                    options={organisationOptions}
                    onChange={(organisationOptions) => {
                      updateOrg(organisationOptions);
                      if (history.location.pathname.includes('report')) {
                        history.push('/reports');
                      } else if (history.location.pathname.includes('consumption')) {
                        history.push('/reports');
                      } else if (history.location.pathname.includes('monthlyreport')) {
                        history.push('/reports');
                      }
                    }}
                  />
                  <div className='clearfix'></div>
                </div>
                <div className='clearfix'></div>
              </div>
            }
          </li>

          <li>
            <SidebarInfoToogleBox className='toggle-right-box'>
              <div onClick={toggleHelp} className='fa fa-info-circle'></div>
            </SidebarInfoToogleBox>
          </li>
        </ul>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  const { whiteLabelId } = state.whiteLabelAdmin || {};
  const organisations = state.admin?.organisations
    ? [...state.admin.organisations, ...state.organisation.list]
    : state.organisation?.list;

  const currentOrganisation = state?.organisation?.details;
  const drgWhiteLabel = whiteLabelId === '7a258d61-0e9f-4d82-9a57-a1f207d483c2';

  const currentOrg = state?.currentOrganisation;

  const profileDetails = state.profile?.details?.profile ? state.profile.details.profile : state.profile.details;

  const organisationOptions = organisations?.map((organisation) => ({
    value: organisation.id,
    label: organisation.name,
  }));

  const uniqueOrgs = _.uniqBy(organisationOptions, 'value');

  const selectedOrganisation = currentOrg && _.find(organisationOptions, { value: currentOrg });

  return {
    showHelpSidebar: state?.help?.show,
    currentOrg,
    organisations,
    currentOrganisation,
    selectedOrganisation,
    organisationOptions: _.sortBy(uniqueOrgs, ['label']),
    fullName: profileDetails && `${profileDetails.firstName} ${profileDetails.lastName}`,
    branding: state.branding,
    drgWhiteLabel,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(LOGINACTIONS.logout()),
  loginWithAws: () => dispatch(LOGINACTIONS.loginWithAws()),
  updateOrganisation: (organisation) => dispatch(ORGANISATIONACTIONS.updateOrganisation(organisation)),
  getOrganisation: () => dispatch(ORGANISATIONACTIONS.getOrganisation()),
  toggleSidebar: () => dispatch(SIDEBARACTIONS.toggleSidebar()),
  toggleHelp: () => dispatch(HELPACTIONS.showHelp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
