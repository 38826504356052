import ACTIONS from './action';
import _ from 'lodash';
import consumptionReducers from './reducers/consumptionReducers';
import facilityReducers from './reducers/facilityReducers';
import organisationReducers from './reducers/organisationReducers';
import metricReducers from './reducers/metricReducers';
import reportReducers from './reducers/reportReducers';
import profileReducers from './reducers/profileReducers';
import helpReducers from './reducers/helpReducers';
import sidebarReducers from './reducers/sidebarReducers';
import authenticationReducers from './reducers/authenticationReducers';
import paymentReducers from './reducers/paymentReducers';
import adminReducers from './reducers/adminReducers';
import usageReducers from './reducers/usageReducers';
import cleanDeep from 'clean-deep';

const domain = window.location.hostname;
const notWLDomains = ['compareyourfootprint', 'localhost'];
const isWhiteLabelApp = !notWLDomains.some((elem) => domain.includes(elem));
const filterSize = [
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

function removeObjectByIds(arr, ids) {
  const arrCopy = Array.from(arr);

  ids.forEach((id) => {
    const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === id);
    if (objWithIdIndex > -1) {
      arrCopy.splice(objWithIdIndex, 1);
    }
  });

  return arrCopy;
}

let stripeClientId;
let stripeKey;
if (['localhost', 'staging', 'dev', 'testcyf'].some((x) => domain.includes(x))) {
  const dev = domain.includes('dev') || domain.includes('localhost');
  const staging2 = window.location.hostname.includes('staging2');
  stripeClientId = dev
    ? process.env.REACT_APP_STRIPE_CLIENT_ID_DEV
    : staging2
    ? process.env.REACT_APP_STRIPE_CLIENT_ID_STAGING2
    : process.env.REACT_APP_STRIPE_CLIENT_ID_STAGING;
  stripeKey = dev
    ? process.env.REACT_APP_STRIPE_DEV_KEY
    : staging2
    ? process.env.REACT_APP_STRIPE_STG2_KEY
    : process.env.REACT_APP_STRIPE_STG_KEY;
} else {
  stripeClientId = process.env.REACT_APP_STRIPE_CLIENT_ID_LIVE;
  stripeKey = process.env.REACT_APP_STRIPE_LIVE_KEY;
}

const defaultState = {
  isLocalDevelopment: domain.includes('localhost'),
  isWhiteLabelApp: isWhiteLabelApp,
  whiteLabelAdmin: {},
  subentities: [],
  consumption: {},
  authentication: {},
  usage: {},
  payment: {
    stripe: {
      stripeClientId: stripeClientId,
      stripeKey: stripeKey,
    },
  },
  sidebar: {
    show: true,
  },
  help: {
    show: false,
  },
  profile: {},
  admin: {
    adminPortal: {},
    filterSize: filterSize,
  },
  currentOrganisation: false,
  report: {
    list: [],
  },
  facility: {
    list: [],
  },
  organisation: {
    list: [],
    fetchedOrganisations: false,
  },
  branding: {},
};

const xeroApiReducer = (state = defaultState, action) => {
  // run each reducer based on the action
  if (action && action.type.indexOf('CONSUMPTION') === 0) {
    return consumptionReducers(state, action);
  }
  if (action && action.type.indexOf('FACILITY') === 0) {
    return facilityReducers(state, action);
  }
  if (action && action.type.indexOf('ORGANISATION') === 0) {
    return organisationReducers(state, action);
  }
  if (action && action.type.indexOf('REPORT') === 0) {
    return reportReducers(state, action);
  }
  if (action && action.type.indexOf('METRIC') === 0) {
    return metricReducers(state, action);
  }
  if (action && action.type.indexOf('AUTHENTICATION') === 0) {
    return authenticationReducers(state, action);
  }
  if (action && action.type.indexOf('PAYMENT') === 0) {
    return paymentReducers(state, action);
  }
  if (action && action.type.indexOf('SIDEBAR') === 0) {
    return sidebarReducers(state, action);
  }
  if (action && action.type.indexOf('PROFILE') === 0) {
    return profileReducers(state, action);
  }
  if (action && action.type.indexOf('HELP') === 0) {
    return helpReducers(state, action);
  }
  if (action && action.type.indexOf('ADMIN') === 0) {
    return adminReducers(state, action);
  }
  if (action && action.type.indexOf('USAGE') === 0) {
    return usageReducers(state, action);
  }

  switch (action.type) {
    case ACTIONS.Types.TRACKING_CATEGORIES: {
      const trackingCategories = action?.payload?.data.TrackingCategories?.map((category) => {
        if (category.Status === 'ACTIVE') {
          return {
            value: category.TrackingCategoryID,
            label: category.Name,
            nestedOptions: category.Options.map((option) => ({
              value: option.TrackingOptionID,
              label: option.Name,
            })),
          };
        }
        return {};
      });

      let newState = _.cloneDeep(state);
      newState.trackingCategories = trackingCategories;
      return newState;
    }

    case ACTIONS.Types.SET_BULK_XERO_OPTIONS: {
      let newState = _.cloneDeep(state);
      const {
        payload: { success, settings },
      } = action || null;

      if (success) {
        const updatedState = {
          ...newState,
          ...settings,
          savedMethod: settings.method,
        };

        updatedState.savedCategory = settings?.selectedTrackingCategory;
        updatedState.savedTrackingOptions = settings?.selectedTrackingOptions;
        delete updatedState.savedAccounts;
        delete updatedState.selectedAccounts;

        return updatedState;
      }
      return newState;
    }

    case ACTIONS.Types.SET_TRACKING_CATEGORY: {
      const { payload } = action;
      const selectedTrackingCategory = {
        label: payload?.Name,
        value: payload.TrackingCategoryID,
        nestedOptions: payload.Options.map((option) => ({
          value: option.TrackingOptionID,
          label: option.Name,
        })),
      };

      let newState = _.cloneDeep(state);
      newState.selectedTrackingCategory = selectedTrackingCategory;

      //if we have a saved category == to the selected and we have saved tracking options then set
      //the selected to the saved.
      if (newState?.savedCategory?.label === newState?.selectedTrackingCategory?.label && newState?.savedTrackingOptions) {
        newState.selectedTrackingOptions = { ...newState?.savedTrackingOptions };
      } else {
        delete newState.selectedTrackingOptions;
      }

      return newState;
    }

    case ACTIONS.Types.SELECT_YEAR: {
      const selectedYear = action.year;

      let newState = _.cloneDeep(state);
      newState.selectedYear = selectedYear;
      return newState;
    }

    case ACTIONS.Types.ALL_TRACKING_OPTIONS: {
      let newState = _.cloneDeep(state);
      newState.allTrackingOptions = action.value;
      return newState;
    }

    case ACTIONS.Types.SAVE_XERO_SETTINGS: {
      let newState = _.cloneDeep(state);
      const { settings } = action.payload || null;

      if (settings) {
        const updatedState = {
          ...newState,
          ...settings,
          savedMethod: settings.method,
        };

        if (updatedState?.savedMethod === 'trackingcategories') {
          updatedState.savedCategory = settings?.selectedTrackingCategory;
          updatedState.savedTrackingOptions = settings?.selectedTrackingOptions;
          delete updatedState.savedAccounts;
          delete updatedState.selectedAccounts;
        }

        if (updatedState?.savedMethod === 'accountcodes') {
          updatedState.savedAccounts = settings?.selectedAccounts;
          delete updatedState.savedCategory;
          delete updatedState.savedTrackingOptions;
          delete updatedState.selectedTrackingOptions;
          delete updatedState.selectedTrackingCategory;
        }
        return updatedState;
      }
      return newState;
    }

    case ACTIONS.Types.SET_ACCOUNT_CODE: {
      const newState = _.cloneDeep(state);

      //spreading or else it mutates savedAccounts if there are any...
      const selectedAccounts = { ...newState.selectedAccounts } || {};
      const xeroAccounts = state.accounts || {};
      selectedAccounts[action.account] = action.value;

      // if clearing out red X, to clear entire entity mapping.
      if (!action.value && !action.removedValue) {
        newState.selectedAccounts[action.account].forEach((removedAccount) => {
          xeroAccounts.push(removedAccount);
        });
        newState.accounts = xeroAccounts;
        delete selectedAccounts[action.account];
      } else if (action.removedValue) {
        // When clearing out an account code
        selectedAccounts[action.account] = action.value;
        newState.accounts = xeroAccounts.concat([action.removedValue]);
      } else {
        selectedAccounts[action.account] = action?.value;
        newState.accounts = xeroAccounts.filter((acc) => acc?.value !== action?.value[action?.value?.length - 1]?.value);
      }

      newState.selectedAccounts = cleanDeep(selectedAccounts);
      if (_.isEmpty(newState.selectedAccounts)) delete newState.selectedAccounts;

      return newState;
    }

    case ACTIONS.Types.SET_SAGE_ACCOUNT_CODE: {
      let newState = _.cloneDeep(state);
      const selectedAccounts = state.selectedAccounts || {};
      const sageAccounts = state.accounts || {};

      // if clearing out red X, to clear entire entity mapping.
      if (!action.value && !action.removedValue) {
        newState.selectedAccounts[action.account].forEach((removedAccount) => {
          sageAccounts.push(removedAccount);
        });
        newState.accounts = sageAccounts;
        delete selectedAccounts[action.account];
      } else if (action.removedValue) {
        // When clearing out an account code
        selectedAccounts[action.account] = action.value;
        newState.accounts = sageAccounts.concat([action.removedValue]);
      } else {
        selectedAccounts[action.account] = action.value;
        newState.accounts = sageAccounts.filter((acc) => acc.value !== action.value[action.value.length - 1].value);
      }

      newState.selectedAccounts = cleanDeep(selectedAccounts);

      if (_.isEmpty(newState.selectedAccounts)) delete newState.selectedAccounts;

      return newState;
    }

    case ACTIONS.Types.SET_TRACKING_OPTION: {
      const newState = _.cloneDeep(state);
      // spreading or else it mutates savedTrackingOptions if there are any...
      const selectedTrackingOptions = { ...newState.selectedTrackingOptions } || {};
      const nestedOptions = [...newState.selectedTrackingCategory?.nestedOptions] || [];

      if (action.action === 'clear') {
        // When clearing out an account code
        const clearedValue = selectedTrackingOptions[action.option];
        selectedTrackingOptions[action.option] = action.value;
        newState.selectedTrackingOptions = cleanDeep(selectedTrackingOptions);
        newState.selectedTrackingCategory.nestedOptions = nestedOptions.concat([clearedValue]);
      } else {
        const prevValue = selectedTrackingOptions[action.option];
        selectedTrackingOptions[action.option] = action.value;
        newState.selectedTrackingOptions = cleanDeep(selectedTrackingOptions);
        newState.selectedTrackingCategory.nestedOptions = nestedOptions.filter((acc) => acc.value !== action.value.value);
        if (prevValue) newState.selectedTrackingCategory.nestedOptions.push(prevValue);
      }

      if (_.isEmpty(newState.selectedTrackingOptions)) delete newState.selectedTrackingOptions;

      return newState;
    }

    case ACTIONS.Types.GET_SETTINGS: {
      let newState = _.cloneDeep(state);
      const { settings } = action.payload || null;

      if (newState.method && settings?.method !== newState.method) {
        newState.savedMethod = settings.method;
        return newState;
      }

      if (settings) {
        const updatedState = {
          ...newState,
          ...settings,
          savedMethod: settings.method,
        };

        if (updatedState?.savedMethod === 'trackingcategories') {
          updatedState.savedCategory = settings?.selectedTrackingCategory;
          updatedState.savedTrackingOptions = settings?.selectedTrackingOptions;
        }

        if (updatedState?.savedMethod === 'accountcodes') {
          updatedState.savedAccounts = settings?.selectedAccounts;
        }

        return updatedState;
      }
      return newState;
    }

    case ACTIONS.Types.DISCONNECT_ORG: {
      let newState = _.cloneDeep(state);

      const { payload } = action;
      // in addition to tenant remove all the other relevant settings so they are reset.
      if (!payload.sameTenant) {
        delete newState.selectedTrackingOptions;
        delete newState.selectedAccounts;
        delete newState.selectedTrackingCategory;
        delete newState.accounts;
        delete newState.trackingCategories;
        delete newState.tenants;
        delete newState.method;
        delete newState.type;
      }

      if (payload.type === 'xero' && payload.connected) {
        newState.connected = payload.connected;
        newState.tenant = payload.xtenantid;
        newState.tenants = payload.tenants;
      } else {
        delete newState.tenant;
        delete newState.connected;
      }

      newState.sageConnected = false;
      return newState;
    }

    case ACTIONS.Types.GET_SAGE_SETTINGS: {
      let newState = _.cloneDeep(state);

      if (action.payload.settings) {
        newState.selectedAccounts = action.payload.settings;
      }

      return newState;
    }

    case ACTIONS.Types.GET_CLAIMS: {
      let newState = _.cloneDeep(state);

      if (action.payload.claims) {
        const updatedState = {
          ...newState,
          claims: action.payload.claims,
        };

        return updatedState;
      }
      return newState;
    }

    case ACTIONS.Types.PROCESS_IMPORT_STATUS: {
      let newState = _.cloneDeep(state);

      if (!action.payload) {
        delete newState.processingImport;
      } else {
        newState.processingImport = {};
        newState.processingImport.status = action?.payload?.data;
      }

      return newState;
    }

    case ACTIONS.Types.UPDATE_PROGRESS: {
      const newState = _.cloneDeep(state);

      const { progress } = action;

      if (progress < 0) delete newState.progress;
      else newState.progress = progress;

      delete newState.usageUploadType;
      delete newState.importUploadKey;
      delete newState.importUploadLink;

      return newState;
    }

    case ACTIONS.Types.ACCOUNT_CODES: {
      const { selectedAccounts } = state || {};
      const selectedAccountsArray = selectedAccounts && Object.values(selectedAccounts);
      const accounts = action.payload.data.Accounts.filter(
        (account) =>
          (account.Type === 'EXPENSE' || account.Type === 'FIXED') &&
          !selectedAccountsArray?.find((selectedAccounts) => _.find(selectedAccounts, { value: account.AccountID }))
      ).map((account) => ({
        value: account.AccountID,
        label: `${account.Name} (${account.Code})`,
        code: account.Code,
      }));

      let newState = _.cloneDeep(state);
      newState.accounts = _.sortBy(accounts, ['label']);
      return newState;
    }

    case ACTIONS.Types.SAGE_ACCOUNT_CODES: {
      const { selectedAccounts } = state || {};
      const selectedAccountsArray = selectedAccounts && Object.values(selectedAccounts);
      const accounts = action.payload.data.$items
        .filter((account) => {
          return (
            account.ledger_account_group.id === 'EXPENSE' &&
            !selectedAccountsArray?.find((selectedAccounts) => _.find(selectedAccounts, { value: account.id }))
          );
        })
        .map((account) => ({
          value: account.id,
          label: account.displayed_as,
          code: account.nominal_code,
        }));

      let newState = _.cloneDeep(state);
      newState.accounts = _.sortBy(accounts, ['label']);
      return newState;
    }

    case ACTIONS.Types.SET_TENANT: {
      let newState = _.cloneDeep(state);

      const { data } = action;

      if (newState.tenant === data.newTenant) {
        // this is the same tenant do nothing
        return newState;
      }

      newState.tenant = data.newTenant;

      const tenantDetails = _.find(newState.tenants, { tenantId: data.newTenant });

      const tenantName = tenantDetails?.tenantName;

      newState.alertMessage = {
        status: true,
        message: `Xero Organisation updated to ${tenantName}`,
        severity: 'success',
        hideDuration: 3000,
      };

      // in addition to tenant remove all the other relevant settings so they are reset.
      delete newState.selectedAccounts;
      delete newState.selectedTrackingCategory;
      delete newState.selectedTrackingOptions;
      delete newState.accounts;
      delete newState.trackingCategories;
      delete newState.savedMethod;
      delete newState.savedCategory;
      delete newState.savedAccounts;
      delete newState.savedTrackingOptions;
      delete newState.method;

      return newState;
    }

    case ACTIONS.Types.SET_SAGE_TENANT: {
      let newState = _.cloneDeep(state);
      if (newState.tenant === action?.data?.xtenantid) {
        // this is the same tenant do nothing
        return newState;
      }

      const { tenant } = action?.data;

      newState.tenant = tenant;

      const tenantDetails = _.find(newState?.tenants, { tenantId: tenant });

      const tenantName = tenantDetails?.tenantName;

      newState.alertMessage = {
        status: true,
        message: `Sage Organisation updated to ${tenantName}`,
        severity: 'success',
        hideDuration: 3000,
      };

      // in addition to tenant remove all the other relevant settings so they are reset.
      delete newState.selectedAccounts;
      delete newState.accounts;

      return newState;
    }

    case ACTIONS.Types.SET_METHOD: {
      let newState = _.cloneDeep(state);
      if (state.method === action.method) {
        // this is the same tenant do nothing
        return newState;
      }

      let method = action.method;
      newState.method = method;

      return newState;
    }

    case ACTIONS.Types.CONNECTED_TO_XERO: {
      let response = action.payload;
      let newState = _.cloneDeep(state);
      const tenants = state?.tenants ? [...state?.tenants] : [];

      newState.connected = response.connected;
      newState.xeroLoginUrl = response.url;
      newState.tenants = response.tenants || tenants;
      if (response.modalProps) {
        const modal = {
          action: 'selectNewConnection',
          item: { selectOrg: true, tenants: newState.tenants },
          modalProps: response.modalProps,
        };
        //settings tenant in case they dont select in the modal.
        newState.tenant = response.tenants[0].tenantId;
        newState.admin.modal = { ...modal, confirmationModal: true };
      } else {
        newState.tenant = response['xero-tenant-id'] || state.tenant;
      }
      return newState;
    }

    case ACTIONS.Types.CONNECTED_TO_SAGE: {
      let response = action.payload;
      const tenants = state?.tenants ? [...state?.tenants] : [];

      let newState = _.cloneDeep(state);
      newState.sageConnected = response.connected;
      newState.tenants = response.tenants || tenants;
      newState.tenant = response['X-Business'] || state.tenant;
      newState.sageLoginUrl = response.url;

      return newState;
    }

    case ACTIONS.Types.SESSION: {
      let response = action.session;

      let newState = _.cloneDeep(state);
      newState.session = response;

      return newState;
    }

    case ACTIONS.Types.GET_SPEND_ENTITIES: {
      let response = action.payload;

      const spendEntities = removeObjectByIds(response, [151957, 562414, 550085]);

      let newState = _.cloneDeep(state);
      newState.spendEntities = spendEntities;

      return newState;
    }

    case ACTIONS.Types.GET_SAGE_SPEND_ENTITIES: {
      let response = action.payload;

      const spendEntities = removeObjectByIds(response, [151957, 562414, 550085]);

      let newState = _.cloneDeep(state);
      newState.spendEntities = spendEntities;

      return newState;
    }

    case ACTIONS.Types.GET_ENTITIES: {
      let response = action.payload;

      let newState = _.cloneDeep(state);
      newState.entities = _.sortBy(response, ['name']);

      return newState;
    }

    case ACTIONS.Types.GET_SUBENTITIES: {
      let response = action.payload;

      let newState = _.cloneDeep(state);
      newState.subentities = _.sortBy(response, ['name']);

      return newState;
    }

    case ACTIONS.Types.GET_LICENSES: {
      const newState = _.cloneDeep(state);

      newState.licenses = action.payload;

      return newState;
    }

    case ACTIONS.Types.SHOW_FOOTER_AGREEMENTS: {
      const newState = _.cloneDeep(state);

      newState.footerAgreements = {
        status: action.status,
        agreement: action.agreement,
      };

      return newState;
    }

    case ACTIONS.Types.SHOW_ALERT_MESSAGE: {
      const newState = _.cloneDeep(state);

      newState.alertMessage = {
        status: action.status,
        message: action.message,
        severity: action.severity,
        hideDuration: action.hideDuration,
      };

      return newState;
    }

    case ACTIONS.Types.SHOW_LOADER: {
      const newState = _.cloneDeep(state);

      newState.showLoader = action.response;

      return newState;
    }

    case ACTIONS.Types.SHOW_MODAL: {
      const newState = _.cloneDeep(state);

      newState.showModal = action.status;

      return newState;
    }

    case ACTIONS.Types.SHOW_ANALYSIS_MODAL: {
      const newState = _.cloneDeep(state);

      newState.showAnalysisModal = { ...newState.showAnalysisModal } || {};
      newState.showAnalysisModal[action.action] = action.status;

      return newState;
    }

    case ACTIONS.Types.GET_FEATURES: {
      const newState = _.cloneDeep(state);

      newState.features = action.payload;

      return newState;
    }

    default:
      return state;
  }
};

export default xeroApiReducer;
