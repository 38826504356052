import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Actions as CONSUMPTION_ACTIONS } from '../../store/actions/consumptionActions';
import { AnchorLink, ConsumptionReportBtn } from '../../styledComponents/GlobalStyle';
import { PeriodMaker } from '../utils/GlobalFunctions';
import GreenhouseEmissionsAndIntensityRatioRow from './GreenhouseEmissionsAndIntensityRatioRow';
import TotalGreenhouseEmissionsByScopeChart from './TotalGreenhouseEmissionsByScopeChart';
import EmissionsBySourceChart from './EmissionsBySourceChart';

const GreenhouseEmissionsAndIntensityRatios = ({
  report,
  totalEmissionsByScope,
  consumptionFilter,
  metrics,
  organisationMetrics,
  organisationDetails,
  toggleConsumptionFilter,
  withMarketFactors,
  industries,
}) => {
  if (!report || !organisationDetails || !organisationMetrics || !totalEmissionsByScope) {
    return null;
  }

  const year = report.year;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  const referenceYear = +report.endDate.split('-')[0];
  const lastPeriod = hasComparative ? PeriodMaker(report.startDate, report.endDate, referenceYear - 1) : null;
  const currentPeriod = PeriodMaker(report.startDate, report.endDate, referenceYear);

  const options = ['m1', 'm2', 'm3', 'revenue', 'co2e'];

  const filterClasses = consumptionFilter.map((item) => `hide-${item}`);

  return (
    <div>
      <style>
        {`.hide-comparative .toggle-comparative { display: none }`}
        {`.hide-market .toggle-market { display: none }`}
        {`.hide-location .toggle-location { display: none }`}
        {options && options.map((key) => `.hide-${key} .toggle-${key} { display: none }`)}
      </style>
      {hasComparative && (
        <AnchorLink
          className={`btn ${consumptionFilter.includes('comparative') ? 'btn-default' : 'btn-success'}`}
          style={{ float: 'right' }}
          onClick={() => toggleConsumptionFilter('comparative')}>
          <i className='fa fa-bar-chart'></i> &nbsp; Check Comparative
        </AnchorLink>
      )}
      &nbsp;
      <div className='clearfix'></div>
      <div className='clearfix'>
        <div id='showReportMetrics-btns' style={{ float: 'right', marginTop: '10px' }}>
          <span>Show: </span>

          <ConsumptionReportBtn
            className={`btn ${consumptionFilter.includes('co2e') ? 'btn-default' : 'btn-success'}`}
            onClick={() => toggleConsumptionFilter('co2e')}
            style={{ marginRight: '5px' }}>
            Co2e
          </ConsumptionReportBtn>

          <ConsumptionReportBtn
            className={`btn ${consumptionFilter.includes('revenue') ? 'btn-default' : 'btn-success'}`}
            onClick={() => toggleConsumptionFilter('revenue')}
            style={{ marginRight: '5px' }}>
            per million Revenue
          </ConsumptionReportBtn>

          {organisationDetails &&
            ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
              const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
              const metricID = orgIndustry?.[ir];
              const metric = metrics && metrics.find((metric) => metric.id === metricID);
              const metricName =
                metric && (metric.name === 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

              return (
                metric &&
                metricID &&
                metricID !== '230393' && (
                  <ConsumptionReportBtn
                    key={index}
                    className={`btn ${consumptionFilter.includes(`m${index + 1}`) ? 'btn-default' : 'btn-success'}`}
                    style={{ marginRight: '5px' }}
                    onClick={() => toggleConsumptionFilter(`m${index + 1}`)}>
                    per {metricName}
                  </ConsumptionReportBtn>
                )
              );
            })}
        </div>
      </div>
      {withMarketFactors && (
        <div id='showLocationOrMarketBased-btns' style={{ float: 'right', marginTop: '10px' }}>
          <span>Show: </span>
          <AnchorLink
            onClick={() => {
              toggleConsumptionFilter('market');
              toggleConsumptionFilter('location');
            }}
            style={{ marginLeft: '1vh' }}
            id='marketBased-btn'
            className={`btn ${consumptionFilter.includes('market') ? 'btn-default' : 'btn-success'}`}>
            Market Based
          </AnchorLink>

          <AnchorLink
            onClick={() => {
              toggleConsumptionFilter('market');
              toggleConsumptionFilter('location');
            }}
            style={{ marginLeft: '1vh' }}
            className={`btn ${consumptionFilter.includes('market') ? 'btn-success' : 'btn-default'}`}
            id='locationBased-btn'>
            Location Based
          </AnchorLink>
        </div>
      )}
      <h3>Total Reported Greenhouse Gas Emissions and Intensity Ratios</h3>
      <br />
      <table
        id='GHGAndIntensityRatios-table'
        className={`table table-hover general-table pie ${filterClasses.join(' ')}`}
        style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th>Scope</th>
            {hasComparative && (
              <th className='toggle-comparative toggle-co2e' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span>
                <br />({lastPeriod})
              </th>
            )}
            <th className='toggle-co2e' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
              Total <span style={{ whiteSpace: 'nowrap' }}>(tCO2e)</span> <br />({currentPeriod})
            </th>
            {hasComparative && (
              <th className='toggle-comparative toggle-revenue' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({lastPeriod})
              </th>
            )}
            <th className='toggle-revenue' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
              tCO2e / <span style={{ whiteSpace: 'nowrap' }}>Revenue</span> <br />({currentPeriod})
            </th>
            {organisationDetails &&
              ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
                const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
                const metricID = orgIndustry?.[ir];
                const metric = metrics && metrics.find((metric) => metric.id === metricID);
                const metricName =
                  metric && (metric.name === 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

                return (
                  metric &&
                  metricID &&
                  metricID !== '230393' && [
                    hasComparative && (
                      <th
                        key={crypto.randomUUID()}
                        className={`toggle-m${index + 1} toggle-comparative`}
                        style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({lastPeriod})
                      </th>
                    ),
                    <th
                      key={crypto.randomUUID()}
                      className={`toggle-m${index + 1}`}
                      style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      tCO2e / <span style={{ whiteSpace: 'nowrap' }}>{metricName}</span> <br />({currentPeriod})
                    </th>,
                  ]
                );
              })}
          </tr>
        </thead>
        <tbody>
          <GreenhouseEmissionsAndIntensityRatioRow scope='1' key={1} />
          <GreenhouseEmissionsAndIntensityRatioRow scope='2' key={2} />
          <GreenhouseEmissionsAndIntensityRatioRow scope='3' key={3} />
          <GreenhouseEmissionsAndIntensityRatioRow key={'All Scopes'} />
        </tbody>
      </table>
      <p>tCO2e – tonnes of carbon dioxide equivalent emissions</p>
      <br />
      <br />
      <div className='flex-dir' style={{ width: '100%', justifyContent: 'space-evenly' }}>
        <div className='bar-chart'>
          <TotalGreenhouseEmissionsByScopeChart />
        </div>
        <div className='donut-chart'>
          <EmissionsBySourceChart />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, withMarketFactors, years, filter } = state.consumption || {};

  const { organisation, report } = state;

  return {
    withMarketFactors,
    report: report?.details,
    totalEmissionsByScope: totalEmissionsByScope,
    years: years || [],
    metrics: state.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    consumptionFilter: filter || [],
    industries: organisation?.industries,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleConsumptionFilter: (filter) => dispatch(CONSUMPTION_ACTIONS.toggleConsumptionFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GreenhouseEmissionsAndIntensityRatios);
